.heading-h1-wrapper-onsiteselling {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.heading-h1-wrapper-onsiteselling .css-b62m3t-container {
    width: 60%;
}

.event-details-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 1em;
    background-color: #aa4c27b3;
    border-radius: 5px;
    color: white;
}
.event-details-tickets-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-details-wrapper h2 {
    font-size: 22px;
}

.event-details-tickets {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.ticket-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5ea88;
    /* margin-bottom: 10px; */
    font-weight: bold;
}

.ticket-wrapper .ticket-name {
    width: 40%;

}

.ticket-wrapper .remaining-tickets {
    padding: 10px;
    background-color: green;
    border-radius: 10px;
    color: white;
}

.ticket-wrapper .quantity {
    width: 15%;
}

.ticket-wrapper .remaining-tickets.soldout, .soldout {
    padding: 10px;
    background-color: red;
    border-radius: 10px;
    color: white;
}

.add-tickets-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
     padding: 0 30px 0 30px;
}

.book-tickets-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 30px;
}

.booktickets-payment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
}

.booktickets-payment .select-payment {
    margin-right: 20px;
}

.booktickets-payment .btn-success {
    width: 100%;
}

.customer-details-form {
    margin: 0 30px 0 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20%;
    margin-bottom: 40px;
    /* margin-top: 40px; */
    border: 1px solid grey;
    height: 300px;
    padding: 40px;
}

.customer-details-form label {
    font-weight: bold;
    margin-bottom: 5px;
}

.tickets-heading {
    font-size: 18px;
    font-weight: bold;
    margin: 20px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-tickets {
    margin: 0 30px 0 30px;
    margin-top: 40px;
}

.download-tickets .alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-wrapper {
    padding: 10px;
    font-weight: bold;
    background-color: #00bcd4;
    font-size: 19px;
}

.seat-number {
    display: block;
    padding: 15px;
    background-color: chocolate;
    color: #fff;
}

.seat-wrapper:not(:last-child) {
    margin-right: 20px;
    position: relative;
}


.tc_seat_selected_else {
  background-color: #ff9800 !important;
  color: #fff;
}

.tc_seat_selected_mine {
    background-color: rgb(113, 0, 226) !important;
    color: #fff;
}

.tc_seat_reserved {
    background-color: #aaaaaa !important;
}

.seating-chart-controls {
    z-index: 9999999;
}