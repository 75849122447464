.orders-h1 {
    font-size: 22px !important;
}
.filter-form-container{
  display:flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
}

.filter-form-checkbox {
  margin: 0 !important;
}

.filterform-label {
    margin-left: 10px;
    font-weight: 700;
    text-decoration: underline;
}

.payment-details-wrapper,
.refunds-details-wrapper {
  display: flex;
  flex-direction: column;
}

.payment-details-wrapper div {
  display: flex;
  flex-direction: column;
}

.payment-details-wrapper label {
  font-size: 18px;
  font-weight: bold;
}

.refunds-details-wrapper div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.refunds-details-wrapper .label {
  font-size: 18px;
  font-weight: bold;
}

.filters-applied {
  background-color: #90fb73;
  color: white;
}

.filters-not-applied {
  background-color: #ebebeb;
  color: black;
}

.orderdetails-paper {
  width:100%;
  padding: 10px;
}

.orderdetails-paper h5 {
  font-size: 1.1rem;
  text-decoration: underline;
}

.orderdetails-paper-content-wraper {
  display: flex;
  flex-direction: column;
}

.orderdetails-paper-content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.orderdetails-paper-content-row label {
  font-weight: 500;
  margin:0;
}
.orderdetails-coupons-loop {
  padding: 10px;
  background-color: cornsilk;
  margin-bottom: 10px;
}

.orderdetails-items-table-wrapper {
  margin-top: 20px;;
}
.table-actions-button {
  text-align: center;
}
.table-actions-button .btn {
  margin-bottom: 6px;
}

.chip-yellow {
  background-color: #ffc107;
  color: black;
}

.chip-black {
  background-color: black;
  color: white;
}

.items-table td {
  text-align: center;
}

.order-totals-bar {
  padding: 10px;
  background-color: #fffdfd;
  margin-bottom: 20px;

}

.order-totals-bar .actions {
    display: flex;
    align-items: flex-end;
}

.order-totals-bar .btn {
  border: 1px solid blue;
  color: blue;
}

.order-totals-bar .btn:nth-child(2),
.order-totals-bar .btn:nth-child(3) {
  margin-left: 15px;
}

.order-totals-bar .totals {
  display: flex;
  flex-direction: column;
}

.order-totals-bar .totals p{
  display: flex;
  justify-content: space-between;
}

.add-reminder-row {
  margin-top: 50px;
  margin-bottom:10px;
  padding: 10px;
  background-color: #fffdfd;
  border: 1px solid #d1cccc;
}

.reminder-form .form-group:nth-child(2),
.reminder-form .form-group:nth-child(3) {
  margin-left: 20px;
}

.reminder-form {
  align-items: flex-start;
  margin-bottom: 20px;
}

.confirm-buttons-email {
  background-color: #953a72;
  border-color: #966884;
  padding: 10px;
  color: white;
  cursor: pointer;
  width: 40%;
}

.confirm-buttons-phone {
  background-color: #007cba;
  border-color: #055881;
  padding: 10px;
  color: white;
  cursor: pointer;
  width: 40%;
}

.supplier-confirm-phone-heading {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #007cba;
    text-align: center;
    margin-bottom: 10px;
}

.supplier-confirm-phone-sub-heading {
  text-align: center;
  margin-bottom: 20px;
}

.orderitem-payment-details p{
  font-size: 14px;
  margin-bottom: 5px;
}

.orderitem-payment-details .first {
  margin-top: 10px;
}

.orderreport-order-tags-wrapper p {
  margin-top: 3px;
  margin-bottom: 3px;;
}


.comment-container {
    padding: 10px;
    background-color: aliceblue;
    margin-bottom: 10px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border: 1px solid #0000000f;
}
