.section-heading {
    background-color: #e6e6e6;
    color: #8c8c8c;
}
.summary-item .content {
    background-color: #f7f7f7;
    padding: 10px;
}

.contractbtns .upload, .upload-invoice {
    color: #00adef;
    text-decoration: underline;
}

.contractbtns .delete, .delete-invoice {
    color: #ff595d;
    text-decoration: underline;
}

.ticketsales-table .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.ticketsales-table .table tfoot {
    background-color: #00adef;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}

.ticketsales-table-invites .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.ticketsales-table-coupons .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.sales-table .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.services-table .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.fees-table .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.special-conditions textarea {
    background-color: #f7f7f7;
}

.marketingbranding .table {
    width: 70%;
}

.marketingbranding .special-conditions-branding {
    width: 25%;
}

.marketingbranding .special-conditions-branding textarea {
    background-color: #f7f7f7;
}

.marketingbranding .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.notif-input {
    background-color: #e6e6e6;
}
.add-more .addmore-btn {
    color: white;
    padding: 10px;
    background-color: #f78e55;
    width: 500px;
    font-weight: bold;
    text-align: center;
}

.view-invoice {
    color: white;
    padding: 10px;
    background-color: #f78e55;
    width: 200px;
    font-weight: bold;
    text-align: center;
}