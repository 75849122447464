body {
  font-family: 'Roboto', sans-serif !important;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .navbar-brand img {
  height: 3rem;
}

.navbar .mr-auto {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.MuiDataGrid-columnHeader {
    text-align: center;
}

.h1 {
    color: #5d4843;
}

.color-orange {
    color: #f1743c;
}

.bg-greyish {
    background-color: #f7f7f7;
}

.bg-color-orange {
    background-color: #f1743c;
}

.color-blue {
    color: #74d0f9;
}

.table-head {
  background-color: #e6e6e6;
  color: #939393;
  font-size: 14px;
}

.menu-subtext {
  position: absolute;
  right: -160px;
  background-color: #e6e6e6;
  display: flex;
  padding: 10px 15px;
  width: 160px;
  justify-content: space-between;
  height: 65px;
  align-items: center;
  font-size: 18px;
  display: none;
}

.nav-link:hover {
  background-color: #e6e6e6;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  /* color: tomato; */
  white-space: normal !important;
  word-wrap: break-word !important;
  text-align: center;
}

.flexcenter {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.btn-orange {
  background-color: #fd7e14;
  color: white;
  border-radius: 0px;
  border: none;
}

.btn-orange:hover {
  cursor: pointer;
  background-color: #b35609 !important;
  color: white;
  border: none;
}

.btn-green {
  background-color: #048723;
  color: white;
  border-radius: 0px;
  border: none;
}

.btn-green:hover {
  cursor: pointer;
  background-color: #037019 !important;
  color: white;
  border: none;
}

.btn-red {
  background-color: #870404;
  color: white;
  border-radius: 0px;
  border: none;
}

.btn-red:hover {
  cursor: pointer;
  background-color: #700303 !important;
  color: white;
  border: none;
}

.btn-yellow {
  background-color: #876604;
  color: white;
  border-radius: 0px;
  border: none;
}

.btn-yellow:hover {
  cursor: pointer;
  background-color: #706e03 !important;
  color: white;
  border: none;
}

.table-ticket-sale .table thead, .table-sales-analysis .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.text5b4842 {
  color: #5b4842;
}

.texte2e2e2 {
  color: #e2e2e2;
}

.textf36523 {
  color: #f36523;
}

.texted1b24 {
  color: #ed1b24;
} 

.text3ab54a {
  color: #3ab54a;
}

.text1a1a1a {
  color: #1a1a1a;
}

.text5e4642 {
  color: #5e4642;
}

.text5c4742 {
  color: #5c4742;
}

.text01bff1 {
  color: #01bff1;
}

.text5e4944 {
  color: #5e4944;
}

.text5f4745 {
  color: #5f4745 !important;
}

.MuiDataGrid-columnHeaderTitle {
  text-transform: uppercase;
  font-size: 14px;
}

.textaeaeae {
  color: #aeaeae;
}

.bgf7f7f7 {
  background-color: #f7f7f7;
}

.bgf0f0f0 {
  background-color: #f0f0f0;
}

.bgf36523 {
  background-color: #f36523;
}

.bg7cc576 {
  background-color: #7cc576;
}

.bgfef200 {
  background-color: #fef200;
}