.table-ticket-sale .table thead, .table-sales-analysis .table thead {
    background-color: #e6e6e6;
    color: #939393;
    font-size: 14px;
}

.subsection-heading {
    color: #604846;
}

h1 {
    color: #5c4742;
}

.graph-btns {
    color: #5c4742;
    border: 1px solid #f36523;
    font-weight: 600;
    border-radius: 0;
    background-color: white;
}

.graph-btns:hover {
    color: white;
    background-color: #f36523;
    border: 1px solid #f36523;
}

.chart {
    background-color: #f9f9f9;
}

.analytics-item-wrapper {
    width: 100%;
    height: 630px;
}

.analytics-item {
    border: 1px solid black;
    width: 100%;
    cursor: pointer;
}

.analytics-item .header {
    font-weight: bold;
    text-align: center;
    color: black;
    background-color: #c2e9a5;
    border-bottom: 1px solid black;
    cursor: pointer;
    padding: 5px;
}

.analytics-item .value {
    text-align: center;
    color: black;
    cursor: pointer;
    padding: 5px;
}

.analytics-item:not(:last-child) {
    margin-bottom: 15px;
}