@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading-h1-wrapper {
  /* padding: 20px; */
}
.heading-h1-wrapper h1{
  font-size: 27px;
}

.page-wrapper {
  margin: 20px;
}

.sidebar-menu {
  width: 5%;
}

.sidebar-menu .nav-link {
  padding: 16px 0px;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-main {
  width: 95%;
}

table th {
  vertical-align: middle !important;
  text-transform: uppercase !important;
}

table.table tbody tr td,
table.table thead tr th,
table.table thead {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}


@media only screen and (max-width: 767px) {
  .content-main {
    width: 100%;
    padding: 0;
    margin-top: 40px;
  }

  .navbar {
    position: fixed;
    width: 100%;
    z-index: 11;
    background-color: #fff;
    padding: 3px 10px!important;
    border-bottom : 1px solid #eeeeee
  }
}
