.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0!important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    margin: 0!important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 15px;
}
.css-o4b71y-MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    margin: 0!important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none!important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    display: none;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0!important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 1px!important;
}