.dashboard-cards {
    margin-bottom: 30px;;
}

.dashboard-cards a {
    width: 18%;
    display: block;
}

.dashboard-cards .card {
    color: #ffffff;
    font-weight: bold;
    border-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    padding: 60px 35px;
    border: none;
    text-align: center;
}

.dashboard-cards .card:not(:last-child) {
    margin-right: 20px;
}

.dashboard-cards .custom-events {
    background-image: linear-gradient(to right top, #ed709c, #f1748e, #f27a81, #f08276, #ec8a6d);
}

.dashboard-cards .sales-events {
    background-image: linear-gradient(to right top, #fbaa7f, #fdb179, #fdb974, #fbc16f, #f7ca6c);
}

.dashboard-cards .events-archive {
    background-image: linear-gradient(to right top, #f687e5, #e27adf, #cd6ed9, #b863d3, #a158cd);
}

.dashboard-cards .prod-list {
    background-image: linear-gradient(to right top, #91498b, #a1427e, #ae3d6d, #b63a5b, #b93d48);
}

.dashboard-cards .customer-nitif {
    background-image: linear-gradient(to bottom, #6a91c8, #789cd1, #86a7da, #94b2e3, #a2bdec);
}

.stats-cards {
    margin-bottom: 30px;
}

.stats-cards .card {
    padding: 26px 30px;
    border: none;
    border-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    width: 30%;
}

.stats-cards .card .title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 2px;
}

.stats-cards .card .title-sub {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 5px;
}

.stats-cards .card .percent {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.stats-cards .orders {
    background-image: linear-gradient(to bottom, #58cd7c, #82d979, #a8e478, #ccee7a, #f0f87f);
}

.stats-cards .tickets {
    background-image: linear-gradient(to bottom, #ffb1be, #ffb4b6, #ffb8ae, #ffbda8, #ffc2a3);
}

.stats-cards .amount {
    background-image: linear-gradient(to bottom, #67a7fe, #50bdff, #4ed1ff, #66e4ff, #88f4fe);
}

.last-orders {
    padding: 10px 16px;
}

.last-orders .title {
    font-weight: bold;
    font-size: 20px;
}

.last-orders .table thead {
    background-color: #e6e6e6;
    color: #bfbfbf;
    font-size: 14px;
}

.last-orders .table thead th {
    color:hsl(0deg 0% 50%);
}


@media only screen and (max-width: 767px) {
    .dashboard-cards {
        padding : 0 ;
        padding-left: 20px;
        padding-right: 10px;
        gap: 10px;
        overflow: scroll;
        margin-bottom: 10px;
    }
    .dashboard-cards::-webkit-scrollbar {
        display: none;
      }
    .dashboard-cards a {
        width: unset;
        font-size: 15px;
    }
    .dashboard-cards .card {
        padding: 3px 13px;
        text-wrap: nowrap;
        border-radius: 5px;
        border: 1px solid #ea580c;
        color: #ea580c;
        background-image: none;
        font-weight: 500;

    }

    .dashboard-cards .card:hover {
        background-color: #ea580c;
        color: white;
    }

    .stats-cards {
        gap: 10px;
        padding: 0px 20px 0px 20px ;
        margin-bottom : 10px
    }
    .stats-cards .card {
        width: 100%;
        padding: 15px 15px;
        border-radius: 2px;
    }
    .stats-sep {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }

    .stats-cards .card .title-sub{
        font-size: 14px;
    }
    .stats-cards .card .percent {
        font-size: 11px;
        color: #fff;
    }
}
